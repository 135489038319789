<template>
   <v-card class="pa-0 pb-2" 
      style="text-align:left;">
      <v-snackbar
         flat
         style="top: 40px;"
         v-model="snackbar"
         dark
         :timeout="snack_timeout"
         :color="colorSnackBar"
         top
      >
         {{ snackbar_message }}
      </v-snackbar>

      <v-card-title style="color:white;" class="px-4 pr-2 py-1 blue darken-2">
         <v-icon color="white" class="mr-2">mdi-account</v-icon>
         Hồ sơ cá nhân
         <v-spacer/>
         <v-btn class="mr-2 text-none" dark
            text link 
            @click="gotoUrl('/cai-dat/user-info-update')">
            Chỉnh sửa
         </v-btn>
         <v-btn icon dark @click="$emit('closeMe')">
            <v-icon>mdi-close</v-icon>
         </v-btn>
      </v-card-title>

      <v-card-text class="pa-3">
      <div style="display: block; padding: 6px; font-size: 16px;">
         <div style="display: inline-block;font-weight: 500;"
            :style="{'width': $vuetify.breakpoint.mdAndUp?'240px':'90px'}">
            Email
         </div>
         <div
            :style="{'text-align': $vuetify.breakpoint.mdAndUp?'left':'right'}"
            style="display: inline-block; font-weight: 600;">
            {{ user.email }}
         </div>
      </div>

      <div style="display: block; padding: 6px; font-size: 16px;">
         <div :style="{'width': $vuetify.breakpoint.mdAndUp?'240px':'120px'}"
            style="display: inline-block; font-weight: 500;">
            Họ và tên:
         </div>
         <div 
            :style="{'text-align': $vuetify.breakpoint.mdAndUp?'left':'right'}"
            style="display: inline-block; font-weight: 600;">
            {{ user.fullname }}
         </div>
      </div>

      <div style="display: block; padding: 6px; font-size: 16px;">
         <div style="display: inline-block; width: 240px; font-weight: 500;">
            Số điện thoại:
         </div>
         <div 
            :style="{'text-align': $vuetify.breakpoint.mdAndUp?'left':'right'}"
            style="display: inline-block; font-weight: 600;">
            {{ user.mobile }}
         </div>
      </div>
      <!--
      <div style="display: block; padding: 6px; font-size: 16px;">
         <div :style="{'width': $vuetify.breakpoint.mdAndUp?'240px':'120px'}"
            style="display: inline-block; font-weight: 500;">
            Quyền truy cập:
         </div>
         <div
            :style="{
               'text-align': $vuetify.breakpoint.mdAndUp?'left':'right',
               color: getRole.roleColor
               }"
            style="display: inline-block; font-weight: 600;"
         >
            {{ getRole.roleStr }}
         </div>
      </div>
      -->

      <div style="display: block; padding: 6px; font-size: 16px;">
         <div
            style="display: inline-block; width: 240px; font-weight: 350;"
         >
            Thay đổi mật khẩu ?
         </div>
         <v-btn color="primary" class="text-none"
            style="height:44px;font-size:18px;"
            :style="{'width': $vuetify.breakpoint.mdAndUp?'auto':'100%'}"
            @click="gotoUrl('/cai-dat/mat-khau')">
            Đổi mật khẩu</v-btn>
      </div>
      </v-card-text>
      <v-card-actions>
         <v-spacer/>
         <v-btn color="error" dark
            class="text-none px-6"
            style="height:44px;font-size:18px;"
            :style="{'width': $vuetify.breakpoint.mdAndUp?'auto':'100%'}"
            @click="callLogout">
            <v-icon class="mr-2">mdi-logout</v-icon>
            Đăng xuất
         </v-btn>
         
      </v-card-actions>
   </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      newPassword: "",
      snackbar: false,
      snack_timeout: 3000,
      snackbar_message: "",
      colorSnackBar: "info",
      dialogUpdatePass: false,
      user: {},
    };
  },
  computed: {
    ...mapGetters({
      userId: "userId",
      token: "token",
      role: "getLevel",
      fullname: "getFullName",
    }),
    getRole() {
      let item = {
         roleStr: "Khách",
         roleColor: "teal"
      };
      if (["ASnind010", "supperadmin"].includes(this.user.role)) {
        item.roleStr = "Super Admin";
        item.roleColor = "indigo";
      }
      if (["ninAnd033", "admin"].includes(this.user.role)) {
        item.roleStr = "Quản trị";
        item.roleColor = "red";
      }
      if (["Perio020", "operator"].includes(this.user.role)) {
        item.roleStr = "Vận hành";
        item.roleColor = "blue";
      }
      if (["neergeen040", "engineer"].includes(this.user.role)) {
        item.roleStr = "Bảo trì hệ thống";
        item.roleColor = "orange";
      }
      if (["tugets050", "guest"].includes(this.user.role)) {
        item.roleStr = "Khách";
        item.roleColor = "teal";
      }
      return item;
    },
  },
  methods: {
    showMessageApp(message, type) {
      this.snackbar = true;
      this.snackbar_message = message;
      this.colorSnackBar = !type ? "info" : type;
    },
    async fetchUserInfo() {
      // console.log(`userID ${this.userId}`)
      // console.log(`token ${this.token}`)
      if (!this.userId || !this.token) return
      try {
         let res = await this.axios.get(
            this.$root.apiUser + "/user/" + this.userId, {
               headers: { "x-token": this.token },
            })
         if (res.data.status == "OK") {
            this.user = res.data.content;
         } else {
              console.log(res.data.message);
         }
      } catch (err) { 
         if( err.response ){ 
            this.showMessageApp("Lỗi! " + err.response.data.message, "error");
            return
         }
         this.showMessageApp("Lỗi! " + err.message, "error");
      }
    },
    callLogout () {
      //   this.$store.dispatch("setLogUser", {
      //     desc: `User '${this.getFullName}' logout`,
      //     userId: this.userId,
      //     manName: this.getFullName,
      //     tags: "logout",
      //     date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      //   });
        
      this.$store.dispatch("logout").then(() => {
         // this.$router.push({path: '/login'})
         window.location='/login'
      })
      this.$emit('closeMe')
    },
    gotoUrl (url) {
       this.$emit('closeMe')
       this.$router.push({path: url})
    }
  },
  async created () {
    await this.fetchUserInfo()
  },
};
</script>
